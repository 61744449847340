
export default {
  inheritAttrs: false,

  props: {
    calculationAccuracy: {
      type: Number,
      default: 0,
    },
  }
};
